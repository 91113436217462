import React from 'react'
import { useTranslation } from 'react-i18next';
import { FaFire, FaStar } from 'react-icons/fa';
import styled from 'styled-components';

export function ProductIcon({ product, selectedOption }) {
  const { t } = useTranslation();
  let productPrice;
  if(!product.prices || !product.prices[selectedOption]) {
    productPrice = product
  }else{
    productPrice = product.prices[selectedOption]
  }
  if (productPrice.is_on_sale) {
    return <RibbonContainer>
            <Ribbon> - {productPrice.sale_percentage}%</Ribbon>
    </RibbonContainer>;
  }else if (product.is_trending) {
    return <RibbonContainer>
          <Ribbon>
              <FaFire className='ribbon-svg'/>
          </Ribbon>
      </RibbonContainer>;
  }
   else if (product.is_essential) {
    return <RibbonContainer>
          <Ribbon>
              <FaStar className='ribbon-svg'/> {t('essential')}
          </Ribbon>
      </RibbonContainer>;
  } else if (product.is_new) {
    return <RibbonContainer>
          <Ribbon>
              <FaStar className='ribbon-svg'/> {t('new')}
          </Ribbon>
    </RibbonContainer>;
  }
  return null;
}
const RibbonContainer = styled.div` 
    position: absolute;
    top: 5%;
    left: 5%;
    padding: 5px 10px;
    line-height: 15px;
    text-align: center;
    background-color: #ff2d47;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${props => props.theme.fontFamily.en_semiBold};
    .ar & {
      font-family: ${props => props.theme.fontFamily.ar_bold};
    }
`;
const Ribbon = styled.span`
    font-size: 12px;
    color: #fff;
    font-family: ${props => props.theme.fontFamily.en_semiBold};
    .ar & {
      font-family: ${props => props.theme.fontFamily.ar_bold};
    }
`;

const PriceContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    
`;
const productPrice = styled.span`
  margin: 0px;
  font-size: 24px;
  color: #ff8a99;
  font-family: ${props => props.theme.fontFamily.en_semiBold};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
`;
const OriginalPrice = styled(productPrice)`
  text-decoration: line-through;
  font-size: initial;
  color: #999;
`;
const CurrentPrice = styled(productPrice)`
`;
  
export function SaleInfo({ product, selectedOption }) {
    const {t} = useTranslation(); 
    let productPrice;
    if(!product.prices || !product.prices[selectedOption]) {
      productPrice = product
    }else{
      productPrice = product.prices[selectedOption]
    }
    return (
        <PriceContainer>

          <CurrentPrice>{t(`currencies.${productPrice.currency}`)} {productPrice.price}</CurrentPrice>
          {productPrice.is_on_sale &&<OriginalPrice>{t(`currencies.${productPrice.currency}`)} {productPrice.original_price}</OriginalPrice>}
        </PriceContainer>
      );
    //use styled-components
}